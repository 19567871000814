<template>
    <div id="order-summary-asset-component">
        <v-row class="ma-0 pa-0 w-100" justify="start">
            <v-col cols="12" sm="6" class="pl-0">
                <div class="d-flex flex-column gap-2">
                    <div class="d-flex gap-2">
                        <v-icon class="font-color-light"> mdi-tag-outline</v-icon>
                        <div>
                            <p class="font-color-normal line-height-12 mb-0 font-weight-bold">
                                {{ $t('card_pending_order_component.asset_number') }}
                            </p>
                            <span class="font-color-light">{{ order.asset.name }}</span><br>
                        </div>
                    </div>

                    <div class="d-flex gap-2">
                        <v-icon class="font-color-light"> mdi-storage-tank-outline</v-icon>
                        <div>
                            <p class="font-color-normal line-height-12 mb-0 font-weight-bold">
                                {{ $t('card_pending_order_component.asset_capacity') }}
                            </p>
                            <span class="font-color-light">{{ Math.round(order.asset.volume_total / 1000) }} m3</span>
                        </div>
                    </div>

                    <div class="d-flex gap-2">
                        <v-icon class="font-color-light"> mdi-map-marker-outline</v-icon>
                        <div>
                            <p class="font-color-normal line-height-12 mb-0 font-weight-bold">
                                {{ $t('card_pending_order_component.delivery_address') }}
                            </p>
                            <span class="font-color-light">{{ order.asset.address }}</span>
                        </div>
                    </div>

                    <div class="d-flex gap-2">
                        <v-icon class="font-color-light"> mdi-water-outline</v-icon>
                        <div>
                            <p class="font-color-normal line-height-12 mb-0 font-weight-bold">
                                {{ $t('card_pending_order_component.quantity') }}
                            </p>
                            <span class="secondary--text font-weight-bold">{{ order.quantity }} m³</span>
                        </div>
                    </div>
                </div>
            </v-col>

            <v-col align-self="center" cols="12" sm="6" class="pr-0">
                <div :class="`position-relative w-fit ${$vuetify.breakpoint.smOnly ? 'ml-auto mr-0' : 'mx-auto'}`">
                    <display-asset-level-component :asset="order.asset" :scale="0.8" />

                    <v-btn class="unsolicited-btn bounce-btn absolute top-0 left-0" color="error" elevation="0" fab x-small>
                        <v-icon class="color-white-fix">mdi-tanker-truck</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DisplayAssetLevelComponent from '@/components/Equipment/LiquidLevelComponent/DisplayAssetLevelComponent.vue'

export default {
    components: { DisplayAssetLevelComponent },
    props: {
        order: {
            type: Object,
            required: true
        }
    }
}
</script>