<template>
    <div id="display-price-component">
        <div>
            <p class="font-weight-bold mb-0 font-14">{{ $t('card_validate_order_component.price_in_m3') }} :</p>
            <template v-if="price && price.cotation_method">
                <div class="d-flex justify-space-between">
                    <span>{{ $t('display_price_component.' + price.cotation_method) }}</span>
                    <span v-if="price.price" class="secondary--text font-weight-bold">{{ price.price }} €/m³</span>
                </div>

                <v-alert
                    v-if="['platts_jour', 'cnr_1', 'platts_mois_1'].includes(price.cotation_method)"
                    border="left"
                    class="mt-2"
                    dense
                    text
                    type="info"
                >
                    {{ $t(`display_price_component.${price.cotation_method}_message`, [this.moment().format('MMMM')]) }}
                </v-alert>
            </template>

            <template v-else>
                <span class="warning--text">{{ $t('display_price_component.price_not_available') }}</span>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DisplayPriceComponent',
    props: {
        price: {
            required: true,
        },
    }
}
</script>