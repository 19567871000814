<template>
    <div id="order-summary-data-component">
        <p class="text-uppercase font-color-medium mb-2">{{ $t('order_summary_data_component.updated_data') }}</p>

        <div class="d-flex flex-column gap-2">
<!--            <div class="d-flex justify-space-between">
                <div>
                    <p class="secondary--text font-weight-bold mb-0 line-height-10">
                        {{ $t('order_summary_data_component.deliverable_volume') }}
                    </p>
                    <span class="font-10 font-color-medium">{{ $t('order_summary_data_component.if_validation_today') }}</span>
                </div>
                <span class="secondary&#45;&#45;text font-weight-bold">
                    {{
                        numberWithSpaces(
                            ((order.asset.volume_total - order.asset.actual_volume) * order.asset.total_useful_volume_percentage) /
                                100
                        )
                    }}
                    L
                </span>
            </div>-->

            <div class="d-flex justify-space-between">
                <span class="font-color-normal font-weight-bold">{{ $t('order_summary_data_component.volume') }}</span>
                <span class="secondary--text font-weight-bold">{{ numberWithSpaces(order.asset.actual_volume) || 0 }} L</span>
            </div>

<!--            <div class="d-flex justify-space-between">
                <span class="font-color-normal font-weight-bold">{{ $t('order_summary_data_component.useful_hollow') }}</span>
                <span class="font-color-medium"> {{ numberWithSpaces(order.asset.volume_total - order.asset.actual_volume) }} L </span>
            </div>-->

            <div class="d-flex justify-space-between">
                <span class="font-color-normal font-weight-bold">{{ $t('order_summary_data_component.consumption') }}</span>
                <span class="secondary--text font-weight-bold">{{ numberWithSpaces(order.asset.daily_consumption) }} L/J</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
}
</script>
